// alle Farben
// Color Changes
//-------------------------------------------------
// darken( $base-color, 10% )
// lighten( $base-color, 10% )
// saturate( $base-color, 20% )
// desaturate( $base-color, 20% )
// adjust-hue( $base-color, 20% )
// Transparency
// rgba( $base-color, .7 )

//Tint & Shade 

$logocolor: #3af;
$linkcolor: #17a2b8;
//$logocolor: #48e0ff;
$texthighlight: #822230;
$light: #ffffff;
$dropdownbackground: #ffffff;
$dropdownbackgrounddark: #000020;
$borderbanner: #888;
$inactivecolor: #aaa;

// $enable-transitions: false;
$carousel-transition-duration:       0.8s !default;
$carousel-transition:                transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)



.carousel-inner>.item {
    -webkit-transition: -webkit-transform 1.2s ease-in-out !important;
    -o-transition: -o-transform 1.2s ease-in-out !important;
    transition: transform 1.2s ease-in-out !important;
}

a:hover,visited,link,active{
//    color: #0f0 !important;
    text-decoration: none;
    outline:0;
}

// Schriftgroessen für alle Auflösungen
// Bootstrap Breakpoints 576 768 992 1200

#maincontent {
//    background-color: #ff0;
}

body, p, div {
    font-family: Roboto, sans-serif;
    font-weight: 400;
//    font-family: Helvetica, Arial, sans-serif;
//    font-weight: 400;
    font-size: 16px; // !important;
//    letter-spacing: -0.0px;
}

/*
.fonts-loaded body, .fonts-loaded p, .fonts-loaded div {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px; // !important;
}
*/


body{
//    color: #ff0000 !important;
    background-color: #ffffff !important;
}

em {
    font-weight: 700;
}

h1,h2,h3,h4 {
    font-family: Nunito, sans-serif;
//    font-family: Helvetica, Arial, sans-serif;
    font-weight: 600;
}

/*
.fonts-loaded h1, .fonts-loaded h2, .fonts-loaded h3, .fonts-loaded h4 {
    font-family: Nunito, sans-serif;
    font-weight: 600;
}
*/

h1{
    padding-top: 18px;
    padding-bottom: 12px;
    letter-spacing: .06em;
}

h2{
    padding-top: 8px;
    padding-bottom: 4px;
    letter-spacing: .048em;
}

h3, h4{
    padding-top: 4px;
    padding-bottom: 0.12em;
}

a:hover{
    text-decoration: none;
}

@media only screen and (max-width: 575px) {
    h1 {
	font-size: 24px !important;
    }
    h2 {
	font-size: 20px !important;
    }
    h3,h4 {
	font-size: 16px !important;
    }
    p, div {
	font-size: 12px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    h1 {
	font-size: 32px !important;
    }
    h2 {
	font-size: 26px !important;
    }
    h3,h4 {
	font-size: 20px !important;
    }
    p, div {
	font-size: 14px !important;
    }
}


@media only screen and (min-width: 768px) {
    h1 {
	font-size: 40px !important;
    }
    h2 {
	font-size: 32px !important;
    }
    h3,h4 {
	font-size: 24px !important;
    }
    p, div {
	font-size: 17px !important;
    }
}


.bg-banner{
    background-color: #dddddd !important;
    border-top: 1px solid $borderbanner;
//    border-top: 1px solid #0ff;
    border-bottom: 1px solid $borderbanner;
}

//---------------------------------------------------------------------
// Navbar Fix
/*
.navbar .nav-item:not(:last-child) {
  margin-right: 35px;
}
*/

.dropdown-menu {
    margin-top: 0 !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 16px !important;
}

@media (max-width: 767px) {
    .dropdown-menu { padding-left:20px !important;
		     border-radius: 0px 0px 0px 0px !important;
		     // border-left: 20px solid #666 !important;
		     border: 0px !important;
		     margin-left:0px !important;
		   }
    div#navbarSupportedContent {
	border-top: 2px solid #ccc !important;
	margin-top: 10px !important;
	padding: 5px;
    }
}

.dropdown-item{
    font-variant: small-caps !important;
    padding: 0px 3px 0px 3px !important;
}

.dropdown-item.inactive {
    color: $inactivecolor;
    background-color: transparent !important;
}

.dropdown-item.active {
    color: #000;
    background-color: transparent !important;
}

.dropdown-toggle::after {
   transition: transform 0.15s linear;
}

.show.dropdown .dropdown-toggle::after {
    transform: translateY(3px);
}


//---------------------------------------------------------------------
// NAVBAR

#navigation{
//    border-bottom: 1px solid #888;
    padding: 0px !important;
//    offsetTop: 40px;
}

// .navigation-top {
.fixed-top {
    position: relative !important;
    top: 60px;
//    position: sticky !important;
//    top: 100px !important;
    right: 0;
    left: 0;
 //   z-index: 20000;
 //   z-index: 50;
    background-color: #000 !important;
 //   transition-duration: 200ms;
}

.navigation-scrolled{ 
    //    z-index: 50;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    background-color: #fffe !important;
    border-bottom: 1px solid #000 !important;

//    background-color: #fffe !important;
    // transition-duration: 200ms;
}
/*
.navbar{
    padding: 0px 0px 0px 0px !important;
}
*/

ul.nav {
//    border: 1px solid #f00;
//    background-color: #0008;
//    padding: 5px 10px 5px 10px;
//    border-radius: 10px;
}


ul.nav > li > a {
    font-family: Nunito, sans-serif;
    font-weight: 600;
//    font-family: Helvetica, Arial, Sans-Serif;
//    font-weight: 400;
    padding: 5px 10px 5px 10px;
    color: #000;
    font-variant: small-caps !important;
    letter-spacing: 0px;
    //    display: inline-block;
    text-decoration: none;
}

//ul.nav > li > a:hover {
//    background-color: #ff0 !important;
//    color: $logocolor;
//}

ul.nav > li > a:active {
//    border: 1px solid green;
    border-radius: 10px 10px 0px 0px;
    background-color: #ffcccc00 !important;
    color: $logocolor !important;
//    font-variant: small-caps;
}

ul.nav > li > ul {
    background-color: #fffe !important;
}

ul.nav > li > ul > li {
//    color: #f0f;
    //    background-color: #0008 !important;
    background-color: #0000 !important;
}
ul.nav > li > ul > li.active:hover {
    color: $logocolor !important;
//    color: #ff0 !important;
}

ul.nav > li > ul > li > a {
    color: #000;
    display: block;
    text-decoration: none;
}

ul.nav > li > ul > li > a:hover {
    color: $logocolor;
    text-decoration: none;
}


ul.nav > li > ul > li > a:active {
    color: $logocolor !important;
//    color: #ccf !important;
}

hr.divider {
    color: $logocolor;
}

#topspacer {
    background-color: #fff;
    position: relative;
    top: 0px;
//    height: 64px;
}

//---------------------------------------------------------------------
// CARD

.card-img-top{
    margin: 8px 10px 0px 10px;
    border: 1px solid #888;
    height: 150px;
    width: 150px !important;
    border-radius:50% 50% 50% 50% !important;
}

.card-title{
    font-family: Nunito, Helvetica, Arial, Sans-Serif;
    font-weight: 600;
    //font-family: Helvetica, Arial, Sans-Serif;
    //font-weight: 400;
    font-size: 20px !important;
    // font-size: 20px;
    height: 50px;
//    margin-bottom: -6px !important;
}

.card-subtitle{
    font-size: 16px !important;
    color: #888;
//    background-color: #f00;
}

.card-text{
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400;
//    color: #f00;
    line-height: 120%;
    height: 110px;

//    font-family: Helvetica, Arial, sans-serif;
//    font-size: 14px !important;
//    font-weight: 400;
//    line-height: 115%;
//    letter-spacing: -0.25px;
//    word-spacing: 0.4px;
//    height: 110px;
}

/*
.card-text-fonts-loaded{
    font-family: Roboto, sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    color: #f00;
    line-height: 120%;
    height: 110px;
}
*/

.card-body{
    margin: 0px;
    padding: 0px;
    background-color: #fff !important;
}

.card{
    width: 220px;
//    padding: 0px;
//    display:inline-block;
    margin:auto;
    margin-top: 15px;
    margin-bottom: 15px;
//    background-color: #f7c !important;
}

//---------------------------------------------------------------------
// IMAGES

.img-twocolumn{
    max-width: 100%;
    height: auto;
    border-radius: 20px;
}

.img-small-radius{
    border-radius: 5px;
}

.img-large-radius{
    border-radius: 20px;
}


//---------------------------------------------------------------------
// AFFILIATED LINKS

a.affiliate {
    color: #999;
    text-decoration: none;
    font-size:14px !important;
    justify-content: center;
}

a.affiliate:hover, visited{
    text-decoration: none;
    color: #999;
}

//---------------------------------------------------------------------

li.divider{
    height: 1px;
}

//li.divider:hover{
//    height: 20px;
//}

hr.divider{
//    border-top: 6px solid $menudark;
//    width: 50%;
//    height: 10px;
//    color: #f00;
    //    background-color: #0f0;
    border: 1px;
//    background-color: rgba(#f00, 0.2);
    background-color: #ccc;
//    width: 50%;
    height: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
//    padding: 0px;
//    margin-bottom: 20px;
//    padding: 10px;
}

a {
    outline: 0;
}

a.footer-link {
    color: $logocolor;
//    color: $linkcolor;
    text-decoration: none;
}
a.footer-link:hover {
    color: $logocolor;
    text-decoration: none;
}

dropdown-toggle {
    height: 30px;
//    padding: 6px 5px ;0
    background-color: #f0c !important;
}

/*
ul.navbar-nav {
    padding-top: 32px;
}
*/

/* WICHTIG FUNZT */
@media (min-width: 768px){

    .dropdown:hover .dropdown-menu {
	display: block;
//	margin-left: 6px !important;
    }
    .dropdown-menu {
	margin-left: 1px !important;
    }

    ul.navbar-nav > li {
	padding-left: 10px;
	padding-left: 5px;
	padding-right: 5px;
    }
    ul.navbar-nav {
	padding-top: 32px;
    }
}

@media (min-width: 992px){
    .dropdown-menu {
	margin-left: 26px !important;
	margin-left: 11px !important;
    }
/*
    .dropdown:hover .dropdown-menu {
	margin-left: 26px !important;
    }
*/
    ul.navbar-nav > li {
	padding-left: 30px;
	padding-left: 15px;
	padding-right: 15px;
    }
}

em {
    font-style: normal;
}

#imprintlink {
    margin-top: 25px;
    border-top: 1px solid #cccccc;
}

#navbar-logo {
    font-size: 1.8em;
}

.container-test{
    margin-top:0px;
    margin-bottom:0px;
    padding-top:0px;
    padding-bottom:0px;
    margin:0px;
}

.container-testl{
    padding:6px;
    margin:0px;
    text-align:left;
}

.container-testc{
    padding:6px;
    margin:0px;
    text-align:center;
}

.container-testr{
    padding:6px;
    margin:0px;
    text-align:right;
}



a.navbar-item {
    color: #0cc;
    display: block;
}

a.navbar-item, a.navbar-item:hover{
    color: #ff00ff;
}


a.navbar-item:hover{
    color: #00ffff;
    text-decoration:none;
}

.nav-link {
    font-size: 16px !important;
    font-variant: small-caps !important;    
    padding: 0px 3px 0px 3px !important;
}

a.nav-item {
    color: #ff4 !important;
    font-size: 16px !important;
    display: block;
}

a.nav-item {
    color: #ccc !important;
    display: block;
}

/* NAVIGATION BAR
 -------------------------------------------------- */


.navbar{
//    padding: 15px 17px 10px 17px !important;
}

//li.active{
//    color: #ff0 !important;
//}

//.dropdown-item.active
//.item .active {
//    background-color: #f00 !important;
//    padding: 0px !important;
//}

//li:hover {
//    background-color: #8ef !important;
//}

li.nav-item > a {
    color: #222;
}

li.dropdown > a {
    color: #222;
    background: none !important;
}

//li.dropdown-item > a {
//    color: #f00 !important;
//    background-color: none !important;
//}

ul > li > a.nav-link:hover,
ul > li > ul > li > a.nav-link:hover{
    color: $logocolor !important;
    // background-color: #bee8ff !important;
    // background-color: #ddd !important;
}

ul > li > a.nav-link {
    color: #000 !important;
    //    padding: 30px 8px 0px 8px !important;
    // padding: 0px 0px 0px 0px !important;
}

.dropdown-item.inactive:hover{
    color: $inactivecolor !important;
}
//.dropdown-item.active{
//    background-color: #f00e !important;
//    padding: 0px;
//}

#navigation {
//    background-color: transparent !important;
}


#navigation .navbar-brand {
    padding-right: 28px;
    width: 280px;
    border-right: 3px solid rgba(255, 255, 255, 0.1);
    font-family: Nunito, Helvetica, Arial, Sans-Serif;
    //font-family: sans-serif;
    font-style: italic;
    // font-size: 26px !important;
    color: #5fcf80 !important;
}
#navigation .navbar-brand .site-logo {
    float: left;
    margin-top: -10px;
    margin-right: 1rem;
    width: 250px;
}



#navbar {
    position: absolute;
    right: 0px;
    bottom: -0px;
}

@media (max-width: 767px) {
    #navigation #navbar {
        float: none !important;
        margin-right: 0;
    }
}
#navigation #navbar .navbar-nav li a {
    color: #b2bbbd !important;
}


@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.eot");
  src: url("/assets/fonts/Roboto-Regular.eot?#iefix") format('embedded-opentype'),
  url("/assets/fonts/Roboto-Regular.woff2?v=1.101") format('woff2'),
  url("/assets/fonts/Roboto-Regular.woff") format('woff'),
  url("/assets/fonts/Roboto-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Bold.eot");
  src: url("/assets/fonts/Roboto-Bold.eot?#iefix") format('embedded-opentype'),
  url("/assets/fonts/Roboto-Bold.woff2?v=1.101") format('woff2'),
  url("/assets/fonts/Roboto-Bold.woff") format('woff'),
  url("/assets/fonts/Roboto-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("/assets/fonts/Nunito-SemiBold.eot");
  src: url("/assets/fonts/Nunito-SemiBold?#iefix") format('embedded-opentype'),
  url("/assets/fonts/Nunito-SemiBold.woff2?v=1.101") format('woff2'),
  url("/assets/fonts/Nunito-SemiBold.woff") format('woff'),
  url("/assets/fonts/Nunito-SemiBold.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

